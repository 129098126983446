<style lang="less" scoped>
.idc {
  width: 100%;
  background: #070911;
  &-third {
    width: 100%;
    height: 650px;
    background: #0f1118;
    position: relative;
    color: rgba(222, 230, 255, 1);
    text-align: center;
    padding-top: 130px;
    &-title {
      font-size: 30px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 4px;
      margin: 0;
    }
    &-subtitle {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 4px;
      margin: 0;
      margin-top: 4px;
    }
    &-container {
      width: 65.2%;
      height: 288px;
      overflow: scroll;
      max-width: 940px;
      margin: 50px auto;
      display: flex;
      justify-content: space-around;
      &-item {
        width: 33%;
        text-align: center;
        img {
          width: 30px;
        }
        p:nth-child(2) {
          font-size: 20px;
          font-weight: 400;
          line-height: 28px;
          margin: 0;
        }
        p:nth-child(3) {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
        }
      }
    }
  }
  .idc-sixth-item {
    font-size: 20px;
    font-weight: 400;
    color: rgba(222, 230, 255, 1);
    line-height: 24px;
    padding: 40px 0;
    &-main {
      width: 76.7%;
      max-width: 1104px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }
  }
  .r-button {
    width: 140px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 29px;
  }
  .r-button-5 {
    margin: 0 auto;
  }
}
</style>

<template>
  <div class="idc">
    <div class="idc-third">
      <div class="idc-third-title">
        {{$t('IDC_third1')}}
      </div>
      <div class="idc-third-subtitle">
        {{$t('IDC_third2')}}
      </div>
      <div class="idc-third-container">
        <div class="idc-third-container-item">
          <img src="../assets/Time.png"
               alt="">
          <p>
            {{$t('IDC_third3_1')}}
          </p>
          <p>
            {{$t('IDC_third3_2')}} <br />
            {{$t('IDC_third3_3')}} <br />
            {{$t('IDC_third3_4')}} <br />
            {{$t('IDC_third3_5')}} <br />
            {{$t('IDC_third3_6')}} <br />
            {{$t('IDC_third3_7')}}
          </p>
        </div>
        <div class="idc-third-container-item">
          <img src="../assets/Server.png"
               alt="">
          <p>
            {{$t('IDC_third4_1')}}
          </p>
          <p>
            {{$t('IDC_third4_2')}} <br />
            {{$t('IDC_third4_3')}} <br />
            {{$t('IDC_third4_4')}} <br />
            {{$t('IDC_third4_5')}} <br />
            {{$t('IDC_third4_6')}}
          </p>
        </div>
        <div class="idc-third-container-item">
          <img src="../assets/Settings.png"
               alt="">
          <p>
            {{$t('IDC_third5_1')}}
          </p>
          <p>
            {{$t('IDC_third5_2')}} <br />
            {{$t('IDC_third5_3')}} <br />
            {{$t('IDC_third5_4')}} <br />
            {{$t('IDC_third5_5')}}
          </p>
        </div>
      </div>
      <div class="r-button r-button-5"
           @click="toMoreDemands">
        <!-- {{$t('signUp')}} --> 企业发布需求
      </div>
    </div>
    <div class="idc-sixth">
      <div class="idc-sixth-item"
           v-for="(item, index) in demandList"
           :key="index"
           :style="{background: item.top ? '#070911': '#0F1118'}"
           @click="routerTo(item)">
        <div class="idc-sixth-item-main">
          <template v-if="item.top">
            <div class="idc-sixth-item-main-left">
              【置顶】{{item.design_type}} -- {{item.design_type2}}
            </div>
            <div class="idc-sixth-item-main-right">
              {{item.title}} >
            </div>
          </template>
          <template v-else>
            <div class="idc-sixth-item-main-left">
              {{item.design_type}} -- {{item.design_type2}}
            </div>
            <div class="idc-sixth-item-main-right">
              {{item.title}} >
            </div>
          </template>
        </div>
      </div>
    </div>
    <Common-activies></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities';
import CommonFooter from '../components/common-footer';

export default {
  name: 'idc',
  data () {
    return {
      showSwiperHover: false,
      ckickSwiper: null,
      demandList: []
    }
  },
  created () {
    this.$api.getDesign_demand_list().then(res => {
      this.demandList = res.demands
    })
  },
  mounted () {
  },
  computed: {
  },
  methods: {
    toMoreDemands () {
      if (JSON.parse(this.$Cookies.get('cecc-user')).org_type === 1) {
        this.$router.push({
          name: 'personCenter',
          params: {
            from: 'moreDemands'
          }
        })
      } else {
        this.$message.warning('该功能仅对企业用户开放，请使用企业账号登录或注册');
      }
    },
    routerTo (item) {
      this.$router.push({
        name: 'demandView',
        params: item
      })
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  }
}
</script>
